import { PATHS } from './page-paths';

export const APP_CONFIG = {
  appName: 'Twin Protocol',
  authenticatedEntryPath: PATHS.HOME,
  unAuthenticatedEntryPath: PATHS.SIGN_IN,
  REDIRECT_URL_KEY: 'redirectPath',
};

export const translationLanguages = [
  { name: 'English', symbol: 'EN', code: 'en' },
  { name: 'Hindi', symbol: 'हिन्दी', code: 'hi' },
  { name: 'French', symbol: 'FR', code: 'fr' },
  { name: 'Arabic', symbol: 'AR', code: 'ar' },
  { name: 'Spanish', symbol: 'ES', code: 'es' },
  { name: 'Mandarin', symbol: '中文', code: 'zh' },
  { name: 'German', symbol: 'DE', code: 'de' },
  { name: 'Japanese', symbol: '日本語', code: 'ja' },
];
