import { StateCreator } from 'zustand';

import { TwinData } from '@/types';

export interface ChatSettings {
  wordLimit: number;
  setWordLimit: (wordLimit: number) => void;
  language: string;
  setLanguage: (language: string) => void;
  audio: Blob | null;
  setAudio: (audio: Blob | null) => void;
  category: Record<string, any> | null;
  setCategory: (category: Record<string, any> | null) => void;
  audioEnabled: boolean;
  setAudioEnabled: (audioEnabled: boolean) => void;
  messages: any[];
  updateMessages: (messages: any[]) => void;
  setMessages: (messages: any[] | ((prevMessages: any[]) => any[])) => void;
  deleteMessages: () => void;
}

export interface TwinSlice {
  twin: TwinData;
  setTwin: (chat: TwinData | null) => void;
}

export const chatSettings: StateCreator<ChatSettings> = (set) => ({
  wordLimit: 50,
  setWordLimit: (wordLimit: number) => {
    set(() => ({ wordLimit }));
  },
  language: 'en',
  setLanguage: (language: string) => {
    set(() => ({ language }));
  },
  audio: null,
  setAudio: (audio: Blob | null) => {
    set(() => ({ audio }));
  },
  category: null,
  setCategory: (category: Record<string, any> | null) => {
    set(() => ({ category }));
  },
  audioEnabled: true,
  setAudioEnabled: (audioEnabled: boolean) => {
    set(() => ({ audioEnabled }));
  },
  messages: [],
  updateMessages: (messages: any[]) => {
    set((state) => ({ messages: [...state.messages, ...messages] }));
  },
  setMessages: (messages: any[] | ((prevMessages: any[]) => any[])) => {
    set((state) => ({
      messages:
        typeof messages === 'function' ? messages(state.messages) : messages,
    }));
  },
  deleteMessages: () => {
    set(() => ({ messages: [] }));
  },
});

export const twinSlice: StateCreator<TwinSlice> = (set) => ({
  twin: null,
  setTwin: (twin: TwinData | null) => {
    set(() => ({ twin }));
  },
});
