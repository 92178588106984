import { useEffect, useState } from 'react';

import { cn } from '@/lib/utils/cn';
import { useSettingsStore } from '@/store';
import { LeftNav } from './nav-menu/LeftNav';
import { RightNav } from './nav-menu/RightNav';

export const Navbar = () => {
  const { settings } = useSettingsStore();

  const themedUi = settings?.ui?.theming?.themedUi;

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <nav
      className={cn(
        'fixed z-10 bg-background top-0 w-full h-16 px-2 sm:px-6 md:px-8 flex items-center justify-between border-b transition-transform duration-300',
        themedUi ? 'bg-primary text-primary-foreground' : '',
        isVisible ? 'translate-y-0' : '-translate-y-full'
      )}
    >
      <LeftNav />
      <RightNav />
    </nav>
  );
};
